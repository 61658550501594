import * as React from 'react';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Input from '../input';
export default function Search(props) {
    const { placeholder = '', value, prefixCls, disabled, onChange, handleClear } = props;
    const handleChange = React.useCallback((e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [onChange]);
    const handleClearFn = (e) => {
        e.preventDefault();
        if (!disabled && handleClear) {
            handleClear(e);
        }
    };
    return (<>
      <Input placeholder={placeholder} className={prefixCls} value={value} onChange={handleChange} disabled={disabled}/>
      {value && value.length > 0 ? (<a className={`${prefixCls}-action`} onClick={handleClearFn}>
          <CloseCircleFilled />
        </a>) : (<span className={`${prefixCls}-action`}>
          <SearchOutlined />
        </span>)}
    </>);
}
