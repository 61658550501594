import * as React from 'react';
import omit from 'rc-util/lib/omit';
import classNames from 'classnames';
import DownOutlined from '@ant-design/icons/DownOutlined';
import Checkbox from '../checkbox';
import Menu from '../menu';
import Dropdown from '../dropdown';
import Search from './search';
import DefaultListBody, { OmitProps } from './ListBody';
import { isValidElement } from '../_util/reactNode';
const defaultRender = () => null;
function isRenderResultPlainObject(result) {
    return (result &&
        !isValidElement(result) &&
        Object.prototype.toString.call(result) === '[object Object]');
}
function getEnabledItemKeys(items) {
    return items.filter(data => !data.disabled).map(data => data.key);
}
export default class TransferList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.defaultListBodyRef = React.createRef();
        // =============================== Filter ===============================
        this.handleFilter = (e) => {
            const { handleFilter } = this.props;
            const { target: { value: filterValue }, } = e;
            this.setState({ filterValue });
            handleFilter(e);
        };
        this.handleClear = () => {
            const { handleClear } = this.props;
            this.setState({ filterValue: '' });
            handleClear();
        };
        this.matchFilter = (text, item) => {
            const { filterValue } = this.state;
            const { filterOption } = this.props;
            if (filterOption) {
                return filterOption(filterValue, item);
            }
            return text.indexOf(filterValue) >= 0;
        };
        this.getCurrentPageItems = () => { };
        // =============================== Render ===============================
        this.renderListBody = (renderList, props) => {
            let bodyContent = renderList ? renderList(props) : null;
            const customize = !!bodyContent;
            if (!customize) {
                bodyContent = <DefaultListBody ref={this.defaultListBodyRef} {...props}/>;
            }
            return {
                customize,
                bodyContent,
            };
        };
        this.renderItem = (item) => {
            const { render = defaultRender } = this.props;
            const renderResult = render(item);
            const isRenderResultPlain = isRenderResultPlainObject(renderResult);
            return {
                renderedText: isRenderResultPlain
                    ? renderResult.value
                    : renderResult,
                renderedEl: isRenderResultPlain ? renderResult.label : renderResult,
                item,
            };
        };
        this.getSelectAllLabel = (selectedCount, totalCount) => {
            const { itemsUnit, itemUnit, selectAllLabel } = this.props;
            if (selectAllLabel) {
                return typeof selectAllLabel === 'function'
                    ? selectAllLabel({ selectedCount, totalCount })
                    : selectAllLabel;
            }
            const unit = totalCount > 1 ? itemsUnit : itemUnit;
            return (<>
        {(selectedCount > 0 ? `${selectedCount}/` : '') + totalCount} {unit}
      </>);
        };
        this.state = {
            filterValue: '',
        };
    }
    componentWillUnmount() {
        clearTimeout(this.triggerScrollTimer);
    }
    getCheckStatus(filteredItems) {
        const { checkedKeys } = this.props;
        if (checkedKeys.length === 0) {
            return 'none';
        }
        if (filteredItems.every(item => checkedKeys.indexOf(item.key) >= 0 || !!item.disabled)) {
            return 'all';
        }
        return 'part';
    }
    // ================================ Item ================================
    getFilteredItems(dataSource, filterValue) {
        const filteredItems = [];
        const filteredRenderItems = [];
        dataSource.forEach(item => {
            const renderedItem = this.renderItem(item);
            const { renderedText } = renderedItem;
            // Filter skip
            if (filterValue && !this.matchFilter(renderedText, item)) {
                return null;
            }
            filteredItems.push(item);
            filteredRenderItems.push(renderedItem);
        });
        return { filteredItems, filteredRenderItems };
    }
    getListBody(prefixCls, searchPlaceholder, filterValue, filteredItems, notFoundContent, filteredRenderItems, checkedKeys, renderList, showSearch, disabled) {
        const search = showSearch ? (<div className={`${prefixCls}-body-search-wrapper`}>
        <Search prefixCls={`${prefixCls}-search`} onChange={this.handleFilter} handleClear={this.handleClear} placeholder={searchPlaceholder} value={filterValue} disabled={disabled}/>
      </div>) : null;
        const { bodyContent, customize } = this.renderListBody(renderList, Object.assign(Object.assign({}, omit(this.props, OmitProps)), { filteredItems,
            filteredRenderItems, selectedKeys: checkedKeys }));
        let bodyNode;
        // We should wrap customize list body in a classNamed div to use flex layout.
        if (customize) {
            bodyNode = <div className={`${prefixCls}-body-customize-wrapper`}>{bodyContent}</div>;
        }
        else {
            bodyNode = filteredItems.length ? (bodyContent) : (<div className={`${prefixCls}-body-not-found`}>{notFoundContent}</div>);
        }
        return (<div className={classNames(showSearch ? `${prefixCls}-body ${prefixCls}-body-with-search` : `${prefixCls}-body`)}>
        {search}
        {bodyNode}
      </div>);
    }
    getCheckBox({ filteredItems, onItemSelectAll, disabled, prefixCls, }) {
        const checkStatus = this.getCheckStatus(filteredItems);
        const checkedAll = checkStatus === 'all';
        const checkAllCheckbox = (<Checkbox disabled={disabled} checked={checkedAll} indeterminate={checkStatus === 'part'} className={`${prefixCls}-checkbox`} onChange={() => {
                // Only select enabled items
                onItemSelectAll(filteredItems.filter(item => !item.disabled).map(({ key }) => key), !checkedAll);
            }}/>);
        return checkAllCheckbox;
    }
    render() {
        const { filterValue } = this.state;
        const { prefixCls, dataSource, titleText, checkedKeys, disabled, footer, showSearch, style, searchPlaceholder, notFoundContent, selectAll, selectCurrent, selectInvert, removeAll, removeCurrent, renderList, onItemSelectAll, onItemRemove, showSelectAll = true, showRemove, pagination, } = this.props;
        // Custom Layout
        const footerDom = footer && footer(this.props);
        const listCls = classNames(prefixCls, {
            [`${prefixCls}-with-pagination`]: !!pagination,
            [`${prefixCls}-with-footer`]: !!footerDom,
        });
        // ====================== Get filtered, checked item list ======================
        const { filteredItems, filteredRenderItems } = this.getFilteredItems(dataSource, filterValue);
        // ================================= List Body =================================
        const listBody = this.getListBody(prefixCls, searchPlaceholder, filterValue, filteredItems, notFoundContent, filteredRenderItems, checkedKeys, renderList, showSearch, disabled);
        // ================================ List Footer ================================
        const listFooter = footerDom ? <div className={`${prefixCls}-footer`}>{footerDom}</div> : null;
        const checkAllCheckbox = !showRemove &&
            !pagination &&
            this.getCheckBox({ filteredItems, onItemSelectAll, disabled, prefixCls });
        let menu = null;
        if (showRemove) {
            menu = (<Menu>
          {/* Remove Current Page */}
          {pagination && (<Menu.Item onClick={() => {
                        var _a;
                        const pageKeys = getEnabledItemKeys((((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || []).map(entity => entity.item));
                        onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove(pageKeys);
                    }}>
              {removeCurrent}
            </Menu.Item>)}

          {/* Remove All */}
          <Menu.Item onClick={() => {
                    onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove(getEnabledItemKeys(filteredItems));
                }}>
            {removeAll}
          </Menu.Item>
        </Menu>);
        }
        else {
            menu = (<Menu>
          <Menu.Item onClick={() => {
                    const keys = getEnabledItemKeys(filteredItems);
                    onItemSelectAll(keys, keys.length !== checkedKeys.length);
                }}>
            {selectAll}
          </Menu.Item>
          {pagination && (<Menu.Item onClick={() => {
                        var _a;
                        const pageItems = ((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || [];
                        onItemSelectAll(getEnabledItemKeys(pageItems.map(entity => entity.item)), true);
                    }}>
              {selectCurrent}
            </Menu.Item>)}
          <Menu.Item onClick={() => {
                    var _a;
                    let availableKeys;
                    if (pagination) {
                        availableKeys = getEnabledItemKeys((((_a = this.defaultListBodyRef.current) === null || _a === void 0 ? void 0 : _a.getItems()) || []).map(entity => entity.item));
                    }
                    else {
                        availableKeys = getEnabledItemKeys(filteredItems);
                    }
                    const checkedKeySet = new Set(checkedKeys);
                    const newCheckedKeys = [];
                    const newUnCheckedKeys = [];
                    availableKeys.forEach(key => {
                        if (checkedKeySet.has(key)) {
                            newUnCheckedKeys.push(key);
                        }
                        else {
                            newCheckedKeys.push(key);
                        }
                    });
                    onItemSelectAll(newCheckedKeys, true);
                    onItemSelectAll(newUnCheckedKeys, false);
                }}>
            {selectInvert}
          </Menu.Item>
        </Menu>);
        }
        const dropdown = (<Dropdown className={`${prefixCls}-header-dropdown`} overlay={menu} disabled={disabled}>
        <DownOutlined />
      </Dropdown>);
        // ================================== Render ===================================
        return (<div className={listCls} style={style}>
        {/* Header */}
        <div className={`${prefixCls}-header`}>
          {showSelectAll ? (<>
              {checkAllCheckbox}
              {dropdown}
            </>) : null}
          <span className={`${prefixCls}-header-selected`}>
            {this.getSelectAllLabel(checkedKeys.length, filteredItems.length)}
          </span>

          <span className={`${prefixCls}-header-title`}>{titleText}</span>
        </div>

        {/* Body */}
        {listBody}

        {/* Footer */}
        {listFooter}
      </div>);
    }
}
TransferList.defaultProps = {
    dataSource: [],
    titleText: '',
    showSearch: false,
};
